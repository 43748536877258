<template>
  <v-app :class="siteMode === 'UH' ? 'signup-app-uh' : 'signup-app'">
    <v-container class="signup-container">
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="siteMode === 'UH' ? logoUH : logoVP"
            class="mt-3"
            contain
            height="150"
          />
        </v-col>

        <v-col class="mb-4">
          <h1
            :class="
              siteMode === 'UH'
                ? 'display-2 black--text font-weight-bold'
                : 'display-2 white--text font-weight-bold'
            "
          >
            {{
              siteMode === 'UH' ? 'University Hospitals' : 'Welcome to VPExam'
            }}
          </h1>
        </v-col>
      </v-row>
      <v-card class="signup-card">
        <v-row align="center" justify="center">
          <v-col>
            <div style="flex-grow: 1;">
              <v-form
                id="form"
                ref="form"
                v-model="valid"
                @submit.prevent="signUp"
              >
                <v-text-field
                  v-model="form.practiceName"
                  name="practiceName"
                  label="Practice Name"
                  type="text"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  dense
                />
                <v-text-field
                  v-model="form.firstName"
                  name="firstName"
                  label="First Name"
                  type="text"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  dense
                />
                <v-text-field
                  v-model="form.lastName"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  dense
                />
                <v-select
                  v-model="specialtiesSelect"
                  :items="specialtiesList"
                  item-text="name"
                  item-value="name"
                  label="Select Specialty"
                  return-object
                  single-line
                  outlined
                  rounded
                  dense
                ></v-select>
                <v-text-field
                  v-model="form.phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  type="text"
                  :rules="phoneRules"
                  outlined
                  rounded
                  dense
                />
                <v-text-field
                  v-model="form.email"
                  name="email"
                  label="Email"
                  type="text"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  dense
                />
                <v-text-field
                  id="password"
                  v-model="form.password"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  label="Password"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  dense
                  @click:append="hidePassword = !hidePassword"
                />

                <v-chip v-if="error" color="red" class="mb-4" label outlined>{{
                  error
                }}</v-chip>

                <v-btn
                  :block="$mq === 'mobile'"
                  color="primary"
                  :loading="loading"
                  @click="signUp"
                  :disabled="!valid"
                  style="float: right;"
                  >Sign Up</v-btn
                >

                <div>
                  <v-btn
                    to="/login"
                    color="primary"
                    style="padding: 0 5px;"
                    text
                  >
                    <span class="mr-1" style="color: #9E9E9E !important;"
                      >Already have an account?</span
                    >
                    Login!
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
import * as fb from '@/firebase'
import { logSignUp } from '../../Events'
import logoUH from '@/assets/logo.svg'
import logoVP from '@/assets/logo-white.png'

export default {
  name: 'Signup',
  components: {},
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE,
      valid: false,
      loading: false,
      error: null,
      hidePassword: true,
      form: {
        practiceName: '',
        firstName: '',
        phoneNumber: '',
        lastName: '',
        email: '',
        password: ''
      },
      specialties: [],
      specialtiesSelect: { id: '', name: '' },
      specialtiesList: [],
      displayName: '',
      rules: {
        required: value => !!value || 'Required.'
      },
      phoneRules: [
        value => !!value || 'Required.',
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            'Invalid phone number'
          )
        }
      ]
    }
  },
  watch: {
    specialties: function() {
      for (const specialty of this.specialties) {
        this.specialtiesList.push({ id: specialty.id, name: specialty.name })
      }
    }
  },
  created() {
    this.$emit(`update:layout`, PublicLayout)
  },
  mounted() {
    this.$bind('specialties', fb.db.collection(`Specialties`))
  },
  methods: {
    async signUp() {
      const users = fb.proxiesCollection

      let examComponents = []
      try {
        await fb.db
          .collection('Default_Exam')
          .get()
          .then(snapshot => {
            for (const item of snapshot.docs) {
              examComponents.push({
                id: item.id,
                ...item.data()
              })
            }
          })
        examComponents.sort((a, b) => (a.order > b.order ? 1 : -1))
      } catch (error) {
        console.log(error)
      }

      this.displayName = this.form.firstName + ' ' + this.form.lastName

      if (
        this.form.firstName &&
        this.form.lastName &&
        this.form.email &&
        this.form.password
      ) {
        fb.auth
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then(async cred => {
            await cred.user
              .updateProfile({
                displayName: 'proxy'
              })
              .then(
                users.doc(cred.user.uid).set({
                  fname: this.form.firstName,
                  lname: this.form.lastName,
                  displayName: this.displayName,
                  email: this.form.email,
                  phoneNumber: this.form.phoneNumber,
                  receiveTextNotifications: true,
                  role: 'Doctor',
                  practiceId: cred.user.uid,
                  practice: this.form.practiceName,
                  isExamEmail: false,
                  isExamText: false,
                  isWaitingEmail: true,
                  isWaitingRoomOpen: true,
                  returningAppt: 15,
                  firstAppt: 30,
                  recordTime: 15,
                  active: true,
                  photoURL:
                    'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/avatar.png?alt=media&token=44b5812d-a7ad-4f80-ac34-5386c88bd4a7',
                  wrImageURL:
                    'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/Assets%2FwaitingRoom_default.png?alt=media&token=e4404fb2-b6e3-4d4f-a09a-2a8c6b73d486',
                  specialty: {
                    id: this.specialtiesSelect.id,
                    name: this.specialtiesSelect.name
                  },
                  examOutline:
                    '<p><strong>General:</strong> awake, alert, non-diaphoretic, no psychomotor agitation, no acute distress.<br><strong>Neurologic: </strong>cranial nerves grossly normal, speech normal rate and rhythm, moved both upper extremities equally.<br><strong>Psychiatric:</strong>&nbsp;normal appearance, normal behavior, and normal attitude; well groomed, pleasant, cooperative.<br><strong>Head:</strong> atraumatic, normocephalic, no rashes or lesions noted.<br><strong>Eyes:</strong> no redness, discharge, swelling, or lesions.<br><strong>Nose:</strong> no redness, swelling, discharge, deformity, or impetigo/crusting.<br><strong>Skin:</strong> no lesions, wounds, erythema, or cyanosis noted on face or hands.<br><strong>Musculoskeletal:</strong> normal range of motion of neck, upper extremities.<br><strong>Cardiopulmonary: </strong>no increased respiratory effort, speaking in clear sentences, inspiratory to expiratory ratio within normal limits.<br><strong>Cardiovascular:</strong>&nbsp;Regular rate and rhythm.&nbsp;Normal S1 and S2.<br><strong>Pulmonary:</strong>&nbsp;Clear to auscultation bilaterally.<br><strong>Lower Extremities:</strong>&nbsp;No LE edema.&nbsp;No varicosities.</p>',
                  examSettings: {
                    medicalHistory: true,
                    symptoms: true,
                    medicalDocuments: true,
                    medications: true,
                    vitals: true,
                    inviteRelative: true,
                    examComponents: true
                  },
                  healthData: {
                    HR: true,
                    WHR: true,
                    BP: true,
                    BG: true,
                    PO: true,
                    BW: true,
                    CFL: true,
                    ECG: true
                  }
                })
              )
          })
          .catch(error => {
            console.log(error)
            this.error = error.message
          })
          .then(practice => {
            practice = fb.practicesCollection.doc(fb.auth.currentUser.uid)
            practice.set(
              {
                name: this.form.practiceName,
                owner: fb.auth.currentUser.uid,
                city: '',
                state: '',
                zip: '',
                street: '',
                dateCreated: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                  new Date()
                ),
                wrImageURL:
                  'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/Assets%2FwaitingRoom_default.png?alt=media&token=e4404fb2-b6e3-4d4f-a09a-2a8c6b73d486'
              },
              { merge: true }
            )
          })
          .then(async () => {
            let id = 0
            for (const item of examComponents) {
              await fb.proxiesCollection
                .doc(fb.auth.currentUser.uid)
                .collection('Exam')
                .doc(id.toString())
                .set(
                  {
                    ...item,
                    order: id
                  },
                  { merge: true }
                )
              id++
            }
          })
          .then(() => {
            logSignUp()
            fb.auth.signOut()
            this.$router.push('/thankyou')
          })
      } else {
        this.error = 'All fields are required'
      }
    }
  }
}
</script>

<style scoped>
#form {
  margin-top: 0;
}

.container {
  max-width: 737px !important;
}

.signup-container {
  max-width: 737px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.signup-card {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 100px;
}
.v-btn {
  text-transform: capitalize;
}

@media (max-width: 768px) {
}
</style>
